import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardHeader, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Helmet} from "react-helmet";
import {BackButton} from "./BackButton.jsx";
import {dateToHumanDateStr, toYYYYMMDD} from "../date.js";
import {getEvents, mapEvents} from "../EventLoader.jsx";
import {venueMap} from "../venue.js";
import Button from "@mui/material/Button";
import {Instagram} from "@mui/icons-material";

const TwoStepLessons = () => {

    let [events, setEvents] = useState([]);

    const title = "Austin 2 Step Lesson Directory";
    const desc = "Find the best 2 lessons in town every day with this calendar";

    const date = new Date();
    const dStr = toYYYYMMDD(date);

    useEffect(() => {
        getEvents(events => setEvents(events))
    }, [dStr])

    events = events.filter(e => e.type === "lesson");
    events = mapEvents(events)

    return <Grid container spacing={2}>
        <Grid item>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:description" content={desc}/>
                <meta property="twitter:title" content={title}/>
                <meta property="twitter:description" content={desc}/>
                <title>{title}</title>
                <meta property="description" content={desc} />
                <link rel="canonical" href="https://austin2step.com/twostep-lessons"/>
            </Helmet>
            <BackButton />
            <Card>
                <CardHeader title={title} subheader={desc} />
                <CardContent>
                    These are the most popular dance lessons for two-step in Austin. All of these lessons are "drop-in", which means you just show up when the class starts, no partner needed. This is the easiest and quickest way to earn some two step experience and then dance afterwards to live music. The true Austin experience.
                </CardContent>
            </Card>
        </Grid>
        {events.map((day,i) =>
            <Grid item xs={12} key={i}>
                <Card>
                    <CardHeader title={"Lessons for "+ dateToHumanDateStr(new Date(day.date + "T12:00:00"))}/>
                    <CardContent>
                        {day.data.map((venue,i) =>
                            <div style={{paddingBottom: "30px"}} key={i}>
                            <Typography variant={"h6"}>{venue.venue} <Button size={"small"} href={`/event/${venue.venueKey}/${day.date}`}>Details</Button></Typography>
                            <Typography variant={"overline"}>{venueMap[venue.venueKey].location}</Typography>

                                {venue.data.map((slot,i) => <div key={i}>{slot.time} {slot.band}<br/><Button target="_blank" href={"https://instagram.com/" + slot.insta} startIcon={<Instagram />}>{slot.insta}</Button></div>)}
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Grid>

        )}
    </Grid>
}

export default TwoStepLessons