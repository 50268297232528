import Card from "@mui/material/Card";
import {CardContent, CardHeader, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {InstagramEmbed} from "react-social-media-embed";
import Alert from "@mui/material/Alert";
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import {venueMap} from "../venue.js";
import {BackButton} from "./BackButton.jsx";

const desc = "More info on the holiday 2-steppin for Christmas Eve, Christmas and New Years Eve";
const title = "Holiday 2-steppin";

export class Event {
    constructor(date, venueKey, insta, cover) {
        Object.assign(this, {
            date: date,
            venueKey: venueKey,
            insta: insta,
            cover: cover
        })
    }
}


const days = {
    "2024-12-24": "Christmas Eve",
    "2024-12-25": "Christmas Day",
    "2024-12-31": "New Year's Eve"
}

const closures = {
    "2024-12-24": ["atx-abgb", "atx-broken-spoke", "atx-continental-club", "atx-cotton-country-club", "atx-coupland", "atx-donns-depot","atx-little-longhorn"],
    "2024-12-25": ["atx-abgb", "atx-broken-spoke", "atx-cotton-country-club", "atx-donns-depot", "atx-duetts", "atx-gueros", "atx-little-longhorn", "atx-luckenbach", "atx-mercer", "atx-sagebrush", "atx-whitehorse"]
}

const dayKeys = Object.keys(days);

export const HolidayGuide = (props) => {

    let [events, setEvents] = useState([]);

    useEffect(() => {
        fetch("/holiday.json")
            .then(res => res.json())
            .then(list => list.reduce((acc, e) => {
                 if (!acc.hasOwnProperty(e.date)) {
                     acc[e.date] = [];
                 }
                 acc[e.date].push(e);
                 return acc
             }, {}))
            .then(e => setEvents(e))
    }, []);

    const style = (window.innerWidth < 500) ? {position: "absolute",  left: -22, width: "100vw"} : {}

    return <Grid container spacing={1}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta property="og:description" content={desc}/>
                    <meta property="twitter:title" content={title}/>
                    <meta property="twitter:description" content={desc}/>
                    <title>{title}</title>
                    <meta property="description" content={desc} />
                    <link rel="canonical" href="https://austin2step.com/holiday-2-steppin"/>
                </Helmet>
                <Grid item xs={12}>
                    <BackButton />
                </Grid>

                        <CardHeader title={title} />
                        <CardContent>
                            Work in progress! The main calendar has all of the usual info. This is for the closures, posters, socials and covers for Christmas Eve/Day and New Years Eve. All input appreciated!
                        </CardContent>
                {dayKeys.map(k =>
                    <React.Fragment key={k}>
                        {/* date header */}
                        <Grid item xs={12}>
                            <Alert icon={false} severity={"info"}>
                                <Typography variant={"h6"}>{days[k]}</Typography>
                                {closures.hasOwnProperty(k) && <Typography variant={"body2"}>Closed: {closures[k].map(vKey => venueMap[vKey].name).join(", ")}</Typography>}
                            </Alert>

                        </Grid>

                        {/*events for this date*/}
                        {events.hasOwnProperty(k) && events[k].map(e =>
                                <Grid item xs={12} md={6} key={e.venueKey}>
                                    {/*<Card>*/}
                                    {/*    <CardContent style={{textAlign:"center"}}>*/}
                                            <InstagramEmbed url={e.insta} style={style}/>
                                            <p>{venueMap[e.venueKey].name} <i>({venueMap[e.venueKey].location})</i></p>
                                            <p>Cover: {e.cover}</p>

                                    {/*    </CardContent>*/}
                                    {/*</Card>*/}
                                </Grid>
                        )}

                        {!events.hasOwnProperty(k) && <Grid item xs={12}>

                                            No posters / IG posts shared yet.

                                </Grid>
                        }

                    </React.Fragment>
                )}
            </Grid>

}