import React, {useRef, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Box, FormControl, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {InstagramEmbed} from "react-social-media-embed";
import {Helmet} from "react-helmet";
import {BackButton} from "./BackButton.jsx";

const api_host = process.env.REACT_APP_API_HOST;

const statusBar = {
    "idle": "",
    "pending": <Alert variant={"filled"} icon={false} severity={"info"}>Sending Email</Alert>,
    "fail": <Alert variant={"filled"} icon={false} severity={"error"}>Email failed to send</Alert>,
    "success": <Alert variant={"filled"} icon={false}>Email sent</Alert>
}

const title = "Contact Austin 2 Step"
const desc = "Have feedback? Need something listed? Need more information? Please feel free to reach out via Email or Instagram"

const Contact = () => {

    let [status, setStatus] = useState("idle")

    const name = useRef("");
    const email = useRef("");
    const subject = useRef("");
    const message = useRef("");

    const sendEmail = (e) => {
        e.preventDefault();
        setStatus("pending");
        fetch(api_host + "/?action=EmailSend", {
            method: "POST",
            body: JSON.stringify({
                name: name.current.value,
                email: email.current.value,
                subject: subject.current.value,
                message: message.current.value
            })
        })
            .then(res => {
                if (res.status === 200) {
                    setStatus("success")
                } else {
                    setStatus("fail")
                }
            })
            .catch(e => setStatus("fail"))
    }



    return <Grid container spacing={2}>
        <Helmet>
            <meta charSet="utf-8"/>
            <meta property="og:description" content={desc}/>
            <meta property="twitter:title" content={title}/>
            <meta property="twitter:description" content={desc}/>
            <title>{title}</title>
            <meta property="description" content={desc} />
            <link rel="canonical" href="https://austin2step.com/contact"/>
        </Helmet>
        <Grid item xs={12}>
            <BackButton />
        </Grid>
        <Grid item xs={12}>
            {statusBar[status]}
        </Grid>
        <Grid item xs={12}>
            <Typography variant={"h4"}>{title}</Typography>
            <Typography variant={"body2"}>{desc}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
            <Card>
                <CardContent>
                    <Box component={"form"} sx={{ '& .MuiTextField-root': { m: 1, width: '50ch' } }}>
                        <Typography variant={"h6"}>Email</Typography>
                        <FormControl>
                            <div>
                                <TextField label={"Your name"} autoComplete={"name"} variant={"filled"} inputRef={name} type={"name"} required />
                            </div>
                            <div>
                                <TextField label={"Email"} autoComplete={"email"} variant={"filled"} inputRef={email} type={"email"} required />
                            </div>
                            <div>
                                <TextField label={"Subject"} variant={"filled"} inputRef={subject} required />
                            </div>
                            <div>
                                <TextField label={"What's on your mind?"} variant={"filled"} inputRef={message} multiline rows={8} required />
                            </div>
                            <div>
                                <Button variant={"contained"} onClick={sendEmail} disabled={status === "pending"}>Submit</Button>
                            </div>
                        </FormControl>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} md={6}>
            <Card>
                <CardContent>
                    <Typography variant={"h6"}>Instagram</Typography>
                    <InstagramEmbed url={"https://instagram.com/austin2step"} />
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default Contact